import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import React, { Suspense, useMemo, useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Line, Text3D } from '@react-three/drei';
import { Bloom, Noise, Glitch, ToneMapping, Vignette, EffectComposer } from '@react-three/postprocessing'
import { Timer } from 'three/addons/misc/Timer.js';
import { gsap } from 'gsap/all';

export default function Model() {
    const [modelLoaded, setModelLoaded] = useState(false);
    const [model, setModel] = useState(null);

    const loader = useMemo(() => {
        const dracoLoader = new DRACOLoader();
        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader);
        return gltfLoader;
    }, []);

    useEffect(() => {
        fetch('./stage.mjs')
            .then(response => response.text())
            .then(text => {
                const modifiedText = 'Z2' + text.substring(2);
                const decryptedArrayBuffer = base64ToArrayBuffer(modifiedText);
                loader.parse(decryptedArrayBuffer, '', function (gltf) {
                    setModel(gltf.scene);
                    setModelLoaded(true);
                }, function (error) {
                    console.log(error);
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Suspense fallback={null}>
            {modelLoaded && <ModelContent model={model} />}
        </Suspense>
    );
}



function ModelContent({ model }) {
    const [rotationZ, setRotationZ] = useState(true);
    const [clicked, setClicked] = useState();
    const textRef = useRef(null);
    const [opacity, setOpacity] = useState(0); // Add opacity state

    const animateRotation = () => {
        if (textRef.current?.material.opacity > 0) {
            gsap.to({ rotationZ: rotationZ - 0.0001 }, {
                duration: 3,
                ease: "none",
                onUpdate: () => {
                    setRotationZ(prevRotationZ => prevRotationZ - 0.000000099);
                }
            });
            //console.log("CLICKED");
        } else if (textRef.current?.material.opacity < 1){
            gsap.to({ rotationZ: rotationZ - 0.001 }, {
                duration: 3,
                ease: "none",
                onUpdate: () => {
                    setRotationZ(prevRotationZ => prevRotationZ - 0.00002);
                }
            });
            //console.log("NOT CLICKED");
        }
    };


    useEffect(() => {
        const animationId = requestAnimationFrame(animateRotation);

        model.traverse((child) => {
            if (child.isMesh) {
                child.material.side = THREE.DoubleSide;
                child.material = new THREE.MeshStandardMaterial({
                    color: "rgb(81, 81, 81)",
                    roughness: 0.4,
                    metalness: 0.9,
                    side: THREE.DoubleSide,
                });
                child.rotation.z = rotationZ;
                setClicked(true);
            }
        });

        return () => cancelAnimationFrame(animationId);
    }, [rotationZ, model, clicked]);

    useEffect(() => {
        if (textRef.current?.materia) {
            textRef.current.material.opacity = opacity;
        }
    }, [opacity]);

    const handleClick = () => {
        //console.log("HANDLECLICK");
        setClicked(true);

        if (textRef.current && textRef.current.material) {
            gsap.to(textRef.current.material, {
                opacity: 1,
                duration: 1,
                ease: "none",
                onUpdate: () => {
                    if (textRef.current) {
                        setOpacity(textRef.current.material.opacity);
                    }
                }
            });
           // console.log(textRef.current.material.opacity, "OK");
        }
    };



    const handlePointerOut = () => {
        //console.log("HANDLECPOINTEROUT");
        setClicked(true);
        if (textRef.current && textRef.current.material) {
            gsap.to(textRef.current.material, {
                opacity: 0,
                duration: 1,
                ease: "none",
                onUpdate: () => {
                    if (textRef.current) {
                        setOpacity(textRef.current.material.opacity);
                    }
                }
            });
            //console.log(textRef.current?.material?.opacity, "OK");
        }
       
    };

    // Rest of the component code...
    
    return (
        <>
            
            <primitive object={model} onPointerOver={handleClick} onPointerOut={handlePointerOut} />
            {clicked && (
                <>
      
                    <group rotation={[0, rotationZ, 0]} >
                    
                        <Line ref={textRef}
                            points={[new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, -0.7, 0.7)]}
                            color={"rgb(61, 61, 61)"}
                            lineWidth={0.5}
                            opacity={opacity}
                            transparent={true}
                        />

                        <Text3D
                           ref={textRef}
                           font="./fonts/helvetiker_regular.typeface.json"
                            size={0.1}
                            //rotation={[Math.PI /2, Math.PI, Math.PI/2]}
                            position={[1, -0.7, 0.7]}
                            height={0.016}
                            
                        >
                            rv@designova.cz
                            <meshStandardMaterial
                                attach="material"
                                color="rgb(81, 81, 81)"
                                roughness={0.3}
                                metalness={0.4}
                                opacity={opacity}
                                transparent={true}
                            />
                        </Text3D>
                    </group>
                </>
            )}
        </>
    );
}

function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
}
