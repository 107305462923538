import './style.css'
import ReactDOM from 'react-dom/client'
import React from 'react'; // Add the import statement for React
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { Fisheye, CameraControls, PerspectiveCamera } from '@react-three/drei'
import { useState } from 'react';
import { Perf } from 'r3f-perf'
const root = ReactDOM.createRoot(document.querySelector('#root'))
import {lerp} from 'three/src/math/MathUtils'
import * as THREE from 'three'

root.render(
  <Canvas>
    {/* <Perf position="top-left" /> */}

   <CameraControls
      //initialPosition={[0, 0, 5]}
      minPolarAngle={0}
      maxPolarAngle={Math.PI / 1.3}
      draggingSmoothTime={2}
      minDistance={4}
      truckSpeed={0.5}
      enableDamping={false}
      maxDistance={7}
      azimuthRotateSpeed={0.5}
      smoothTime={10}
      onEnd={(event) => {
        setTimeout(() => {
          event.target.reset({});
        }, 1000); // Delay reset by 1 second (1000 milliseconds)
        //console.log(event.target);
      }}
    />
    
    <group>
      <Experience />
    </group>
  
    <PerspectiveCamera makeDefault position={[0, 0, 5]}  fov={50} />
  </Canvas>
);
  