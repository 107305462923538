import { Suspense } from 'react'
import Model from './Ova.jsx'
import { useFrame } from '@react-three/fiber'
import { useState, } from 'react';
import { useControls, } from 'leva'; // Add this import statement


export default function Experience() {
    const [rotation, setRotation] = useState(0);

    // Use leva controls for all model rotation axis
    const { rotationX, rotationY } = ({
        rotationX: 45.5,
        rotationY: 0.03,
       
    });

    useFrame(() => {
        setRotation((prevRotation) => prevRotation - 0.0065);
    });


          return (
        <>
         
            <directionalLight castShadow position={[3, 3, 5]} intensity={4.5} shadow-normalBias={0.08} />
            <ambientLight intensity={1.5} />

            
                <Model rotation={[rotationX, rotationY, rotation]} />
            
        </>
    );
}